// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---themes-gatsby-theme-wpgrapql-src-pages-1031-exchanges-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/1031-exchanges.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-1031-exchanges-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-404-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/404.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-404-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-about-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/about.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-about-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-contact-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/contact.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-contact-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-dst-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/dst.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-dst-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-home-with-loader-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/home-with-loader.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-home-with-loader-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-index-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/index.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-index-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-investors-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/investors.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-investors-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-news-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/news.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-news-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-old-news-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/old-news.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-old-news-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-playground-chart-animation-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/playground/chartAnimation.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-playground-chart-animation-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-playground-e-animation-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/playground/eAnimation.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-playground-e-animation-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-playground-logo-animation-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/playground/logoAnimation.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-playground-logo-animation-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-playground-scrolltest-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/playground/scrolltest.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-playground-scrolltest-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-portfolio-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/portfolio.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-portfolio-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-private-client-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/private-client.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-private-client-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-strategy-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/strategy.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-strategy-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-pages-value-add-funds-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/pages/value-add-funds.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-pages-value-add-funds-js" */),
  "component---themes-gatsby-theme-wpgrapql-src-templates-portfolio-index-js": () => import("./../../../../../themes/gatsby-theme-wpgrapql/src/templates/portfolio/index.js" /* webpackChunkName: "component---themes-gatsby-theme-wpgrapql-src-templates-portfolio-index-js" */)
}

